<template>
  <div class="content about">
    <banner title="About Us" :image="1"> </banner>

    <div class="section">
      <div class="content_wrapper">
        <h3>Who We Are</h3>
        <p>
          Founded in 2018, Taproot Energy Partners provides world-class
          midstream services to its customers in the Northeastern Extension of
          Colorado's prolific DJ Basin. Taproot is privately held and backed by
          Energy Spectrum Capital. The Taproot team has an extensive, proven
          history of successful greenfield development projects and efficient
          operations.
        </p>
        <p>
          Taproot currently owns and operates over 100 miles of crude gathering
          and transportation pipeline and over 60 miles of produced water
          gathering pipeline. Taproot also operates a robust fresh water supply,
          storage and distribution system. Our focus on collaboration with
          producers and commitment to safety have earned Taproot its highly
          regarded reputation in the DJ Basin and beyond.
        </p>
        <img class="image" src="../assets/images/pic1.jpg" alt="Sample image" />
      </div>
    </div>

    <div class="section">
      <div class="content_wrapper">
        <h3>Our Partner</h3>
        <p>
          Taproot is backed by Energy Spectrum Capital (“Energy Spectrum”).
          Based in Dallas, Texas, Energy Spectrum was founded in 1996 to manage
          private equity funds that make direct investments in well-managed
          companies that acquire, develop and operate midstream energy assets.
          Since inception, Energy Spectrum has raised more than $3.5 billion of
          equity capital commitments from corporate and public pension funds,
          insurance companies, endowments, bank and other institutional
          investors. For more information, please visit www.energyspectrum.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "About-view",
  components: {
    Banner,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
